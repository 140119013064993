import React from "react";
// import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
const Python = () => {
//   const codeString = `
// a = 2+3
// b = a+5
// print(a+b)
// `;

  return (
    <div>
      <h2>Learn Python Like A Pro</h2>
      <section>
        {/* <b>Select a topic</b> */}
        <h1>Coming Soon</h1>
      </section>
    </div>
  );
};

export default Python;
