import { BLUE, DARK } from "./themeTypes"

export const setDark = ()=>{
    return {
        type: DARK
    }
}

export const setBlue = ()=>{
    return {
        type: BLUE
    }
}